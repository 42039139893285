// async function GetMessages () {
//   return fetch('messages.json')
//     .then(response => response.json())
// }
//
import keys from '@/i18n/keys'

export default {
  install (Vue) {
    Vue.config.globalProperties.$k = keys
  }
}
