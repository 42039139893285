import { actionSignature } from "@/Methods/ActionSignature";
import { AdminSignatureDataByName, TransactionSignatures } from "@/modules/Signatures";
import { SignService } from "@/Services/SignService";

const adminItemsMutator = {
  processTypeCurrencyLimits (data) {
    return {
      fields: data,
      keys: [data.organization_id, data.process_type, data.currency_type]
    }
  }
}

export default class {
  constructor (key) {
    this.signService = new SignService(key, localStorage.getItem('user_id'))
  }

  getSignedRequest (adminItemName, data) {
    let sortedData = {}
    // Для подписи используются отсортированные свойства в объекте
    Object.keys(data).sort((a, b) => a.localeCompare(b)).forEach(prop => {
      sortedData[prop] = data[prop]
    })
    if (adminItemsMutator[adminItemName]) sortedData = adminItemsMutator[adminItemName](sortedData)
    return this.signService.createSignedRequest(
      actionSignature(...AdminSignatureDataByName[adminItemName]),
      sortedData
    )
  }
}
