export default {
  install (Vue, options) {
    // console.log('Validation plugin start')
    /**
     * Валидация данных
     * @param sendingData {typeValidation: String, dataForValid: Object}
     * @returns {boolean}
     */
    Vue.config.globalProperties.$NewValid = function (sendingData) {
      let result = true
      let validationParams = sendingData.dataForValid
      switch (sendingData.typeValidation) {
        case 'AmountLimitations':
          let arrayUserAmount = String(validationParams.amount).split('.')
          let userScale = 0
          if (arrayUserAmount[1]) {
            userScale = arrayUserAmount[1].length
          }
          if (Number(validationParams.amount) < Number(validationParams.minValue)) {
            result = false
          } else if (userScale > Number(validationParams.scale)) {
            result = false
          } else {
            result = true
          }
          break
        case 'login':
          if (validationParams.login[0] === 'a') result = true
          else result = !!(validationParams.login.length === 12 && validationParams.login.match(/^\d+$/));
          break
        case 'email':
          const email = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
          result = !!(validationParams.email.length >= 7 && validationParams.email.length <= 64 && validationParams.email.match(email));
          break
        case 'wallet':
          result = (validationParams.wallet.match(/W_+(.{8})+-(.{4})+-(.{4})+-(.{4})+-(.{12})/) && validationParams.wallet.length === 38) || validationParams.wallet.length === 0;
          break
        case 'account':
          result = (validationParams.wallet.match(/A_+(.{8})+-(.{4})+-(.{4})+-(.{4})+-(.{12})/) && validationParams.wallet.length === 38) || validationParams.wallet.length === 0;
          break
        case 'password':
          const digits = /\d/g
          const capitalLetter = /[A-Z]/g
          const smallLetter = /[a-z]/g
          result = !!((validationParams.password.length >= 8 && validationParams.password.length <= 64) && validationParams.password.match(digits) && validationParams.password.match(capitalLetter) && validationParams.password.match(smallLetter));
          break
        case 'PemKey':
          result = !!validationParams.key.match(/^-----BEGIN\s+EC\s+PRIVATE\s+KEY-----[\w\W]+-----END\s+EC\s+PRIVATE\s+KEY-----$/gim);
          break
        case 'repPassword':
          result = validationParams.password === validationParams.repPassword;
          break
      }
      return result
    }
  }
}
