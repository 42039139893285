<template>
  <div
    v-if="flagShowLoader"
    class="MainLoader"
  >
    <img
      class="Loader"
      src="../../assets/LoaderCryptoenter.gif"
      alt=""
    >
  </div>
</template>

<script>
export default {
  computed: {
    flagShowLoader: function () {
      return this.$store.getters.GetLoaderFlag
    }
  }
}
</script>

<style lang="stylus">
  .Loader
    height 150px
    width 150px
  .MainLoader
    position fixed
    top 0
    bottom 0
    right: 0
    left: 0
    background-color white
    display flex
    align-items center
    justify-content center
    z-index 10001
</style>
