export default {
  install (Vue) {
    // console.log('Change icon plugin start')
    let favicon = document.getElementById('favicon')
    let href = favicon.href
    let interval = null

    Vue.$StartChange = function () {
      if (interval) {
        clearInterval(interval)
        favicon.href = href
      }
      interval = setInterval(function () {
        if (favicon.href === href) {
          favicon.href = favicon.dataset.href
        } else {
          favicon.href = href
        }
      }, 500)
    }

    Vue.$StopChange = () => {
      if (interval) clearInterval(interval)
      favicon.href = href
    }
  }
}
