import { notify } from '@kyvg/vue3-notification'
import Store from '../../store'
import Noty from 'noty'

export function TimeoutNotifier (sessionState) {
  const me = this
  me.showMessage = showMessage
  let resumeAnswer = false
  // count down task handle
  let countDownTask = null
  // user decision - either he wants to continue session or not
  // let deferred = null

  // add 'session' to the global scope to use it in notification template which has no controller
  let notificationDuration = false
  /**
   * Returns user's decision (promise) either he wants to continue session or not.
   */
  function showMessage () {
    let session = {
      secondsRemains: sessionState.timeoutThreshold / 1000,
      keepAlive: keepAlive
    }

    function cancelCountDown () {
      if (countDownTask) {
        clearInterval(countDownTask)
      }
      sessionEndNotify.close()
    }

    // 'Continue' button handler
    function keepAlive () {
      Store.dispatch('refreshSession')
        .then(() => {
          notify({
            type: 'success',
            title: window.$t('common_success'),
            text: window.$t('common_session_is_continued')
          })
          resumeAnswer = true
        })
        .catch(() => {
          notify({
            type: 'error',
            title: 'Cannot contact server',
            text: 'Communication error, please try again later.'
          })
          resumeAnswer = false
        })
    }

    let sessionEndNotify = new Noty({
      type: 'alert',
      text: `${window.$t('session_end_text_1')} <span class="NotyText-Timer2">60</span> ${window.$t('session_end_text_2')}`,
      timeout: notificationDuration,
      layout: 'center',
      theme: 'mint',
      buttons: [
        Noty.button('Continue', 'btn btn-success', keepAlive, { id: 'button1', 'data-status': 'ok' })
      ]
    })

    return new Promise(function (resolve, reject) {
      notificationDuration = sessionState.timeoutThreshold
      const secondsRemains = notificationDuration / 1000
      session.secondsRemains = secondsRemains

      const startCountdown = new Date()
      countDownTask = setInterval(() => countDown(startCountdown), 1000, secondsRemains)
      sessionEndNotify.show()

      let timer = 60
      let interval = setInterval(() => {
        if (timer > 0) {
          timer--
          document.getElementsByClassName('NotyText-Timer2')[0].innerHTML = timer
        } else {
          session.secondsRemains = 0
          clearInterval(interval)
        }
      }, 1000)

      function countDown (initialTime) {
        const seconds = Math.abs((initialTime - new Date()) / 1000)
        if (seconds > 60) {
          clearInterval(countDownTask)
          sessionEndNotify.close()
          window.location.replace('/lk/login')
        }
        if (resumeAnswer) {
          resumeAnswer = false
          cancelCountDown()
          clearInterval(interval)
          resolve()
        }
        if ((--session.secondsRemains <= 0 && !resumeAnswer) || Date.now() - sessionState.lastAccessTime > sessionState.timeout) {
          cancelCountDown()
          reject(sessionEndNotify)
        }
      }
    })
  }
}
