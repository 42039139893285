export default {
  install (Vue) {
    // console.log('Work date plugin')
    Vue.config.globalProperties.$ConverDateToUTCFormat = function (date) {
      let arrDate = date.split('.')
      return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`
    }
    Vue.config.globalProperties.$CreateDateOfTimeStep = function (timestamp) {
      let date = new Date(timestamp * 1000)
      let dd = date.getDate()
      if (dd < 10) dd = '0' + dd

      let mm = date.getMonth() + 1
      if (mm < 10) mm = '0' + mm

      let yy = date.getFullYear()
      if (yy < 10) yy = '0' + yy

      return `${dd}.${mm}.${yy}`
    }
    Vue.config.globalProperties.$CreateTimeOfTimeStep = function (data) {
      let date = new Date(data)
      let hh = date.getHours()
      if (hh < 10) hh = '0' + hh

      let mm = date.getMinutes()
      if (mm < 10) mm = '0' + mm

      return `${hh}:${mm}`
    }
  }
}
