import { FIAT_CURRENCIES } from "@/constants/input";

export default {
  install (Vue) {
    // console.log('Type currency plugin')
    Vue.config.globalProperties.$CurrencyTypeDefinition = function (currency) {
      let type = ''
      switch (currency) {
        case 'zcash':
        case 'litecoin':
        case 'dogecoin':
        case 'dash':
        case 'bitcoingold':
        case 'bitcoincash':
        case 'bitcoin':
          type = 'btc'
          break
        case 'ethereum':
        case 'ethereumclassic':
          type = 'eth'
          break
      }
      return type
    }
    Vue.config.globalProperties.$CurrencyName = function (currencyCode) {
      let CurrencyInfo = JSON.parse(localStorage.getItem('currencyInfo'))
      let info = {}
      CurrencyInfo.forEach(item => {
        if (item.code === currencyCode) {
          info = item.name
        }
      })
      return info
    }
    Vue.config.globalProperties.$GetCurrencyType = function (currencyCode) {
      let currencyInfo = JSON.parse(localStorage.getItem('currencyInfo'))
      let result
      currencyInfo.forEach(item => {
        if (currencyCode === item.code) {
          result = item.type
        }
      })
      return result
    }
    Vue.config.globalProperties.$GetCurrencyCode = function (currencyCode) {
      let currencyInfo = JSON.parse(localStorage.getItem('currencyInfo'))
      let result
      currencyInfo.forEach(item => {
        if (currencyCode === item.code) {
          // result = item.type === 'genericFiat' ? 'genericFiat' : item.name
          result = FIAT_CURRENCIES.includes(item.type) ? 'genericFiat' : item.name
        }
      })
      return result
    }
    Vue.config.globalProperties.$GetCurrencyCodeForName = function (currencyCode) {
      let currencyInfo = JSON.parse(localStorage.getItem('currencyInfo'))
      let result
      currencyInfo.forEach(item => {
        if (currencyCode === item.name) {
          result = item.code
        }
      })
      return result
    }
  }
}
