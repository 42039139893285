<template>
  <div
    v-if="flagShowJoinModal"
    class="FormJoin"
    :style="{ width: width + 'px' }"
  >
    <span
      class="FormJoin-CloseButton"
      @click="closeModal"
    />
    <input
      v-model="Name"
      type="text"
      placeholder="Enter your name"
    >
    <input
      v-model="Email"
      type="text"
      placeholder="Enter E-mail"
    >
    <textarea
      id=""
      v-model="Description"
      name=""
      cols="30"
      rows="10"
      placeholder="Please enter a few words about yourself and your goals."
    />
    <button @click="sendJoinFrom">
      Send
    </button>
  </div>
</template>

<script>
let widthScreen = window.innerWidth
export default {
  data () {
    return {
      Name: '',
      Email: '',
      Desciption: ''
    }
  },
  computed: {
    width: function () {
      return widthScreen / 2
    },
    flagShowJoinModal: function () {
      return this.$store.getters.GetJoinModalFlag
    }
  },
  methods: {
    closeModal () {
      this.$store.commit('SetJoinModalFlag', false)
    },
    sendJoinFrom () {
      let sendingData = {
        name: this.Name,
        email: this.Email,
        descr: this.Desciption
      }
      this.$store.commit('SetLoaderFlag', true)
      this.$store.dispatch('joinForm', sendingData)
        .then(() => {
          this.$notify({
            title: this.$t('error_title'),
            text: 'Ваша завяка успешно отправлена',
            type: 'success'
          })
        })
        .catch(() => {
          this.$notify({
            title: this.$t('error_title'),
            text: 'Что-то пошло не так, ваша завяка не отправлена',
            type: 'error'
          })
        })
        .finally(() => {
          this.Name = ''
          this.Email = ''
          this.Desciption = ''
          this.$store.commit('SetJoinModalFlag', false)
          this.$store.commit('SetLoaderFlag', false)
        })
    }
  }
}
</script>

<style lang="stylus">
  .FormJoin
    display flex
    flex-direction column
    justify-content space-around
    align-items flex-start
    padding 50px
    position fixed
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    height 400px
    background-color white
    border 3px solid black
    border-radius 10px
    &-CloseButton
      display block
      position absolute
      right 10px
      top 10px
      width 30px
      height 30px
      background-color: black
      cursor pointer
      &:before, &:after
        content ''
        display block
        height 3px
        width 100%
        position absolute
        top 13px
        border-radius 50%
        background-color: #fff
      &:before
        transform rotate(45deg)
      &:after
        transform rotate(-45deg)
</style>
