const SESSION_TIMEOUT = 'app.session.timeout'
const defaultTimeout = 15 * 60 * 1000 // default - 15 minutes
const SESSION_LOGGED_IN = 'app.session.loggedIn'
// number of millis to notify user before timeout occurs (threshold)
const timeoutThreshold = 60 * 1000 // default - 60 seconds, constant value, not for change in runtime

// private state
let lastAccessTime = null

export class SessionState {
  get loggedIn () {
    return sessionStorage.getItem(SESSION_LOGGED_IN) === 'true'
  }

  set loggedIn (loggedIn) {
    sessionStorage.setItem(SESSION_LOGGED_IN, '' + loggedIn)
  }

  get timeoutThreshold () {
    return timeoutThreshold
  }

  get lastAccessTime () {
    return lastAccessTime
  }

  get timeout () {
    return sessionStorage.getItem(SESSION_TIMEOUT) || defaultTimeout
  }

  setTimeoutSeconds (timeoutSeconds) {
    if (timeoutSeconds && timeoutSeconds > 1) {
      sessionStorage.setItem(SESSION_TIMEOUT, timeoutSeconds * 1000)
    }
  }

  updateLastAccessTime () {
    if (this.loggedIn) {
      lastAccessTime = Date.now()
    }
  }
}
