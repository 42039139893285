import { SessionState } from './session.state'
import { TimeoutNotifier } from './timeoutNotifier.service'
// import Router from '../../router'
import Store from '../../store'
import { notify } from '@kyvg/vue3-notification'

const Session = {
  install (Vue) {
    // console.log('Plugin session start')
    let sessionState = new SessionState()
    let timeoutNotifier = new TimeoutNotifier(sessionState)
    let timeoutCheckerTask = null
    let checkInterval = 0

    function cancel () {
      if (timeoutCheckerTask) {
        clearInterval(timeoutCheckerTask)
        timeoutCheckerTask = null
      }
    }

    /**
     * End session timeout checking, should be called on logout.
     */
    function finish () {
      sessionState.loggedIn = false
      cancel()
    }

    /**
     * Check when user made last interactions with backend and
     * notify him 60 seconds (threshold) before session is going to time out
     * if he had no interactions and session is about to expire on backend.
     */
    function checkSessionTimeout (intervalStartedDate, once) {
      // inactivity interval correction in millis
      // we increase inactivity duration by this amount
      // to consider time spent by response to come from backend to frontend
      const PRECISION_CORRECTION = 500

      const inactivityInterval = (Date.now() - sessionState.lastAccessTime) + PRECISION_CORRECTION
      if (inactivityInterval >= checkInterval) {
        timeoutNotifier.showMessage()
          .then(() => {
            cancel()
            scheduleNextCheck()
          }) // user continued session, continue watching
          .catch((sessionEndNotify) => {
            sessionEndNotify.close()
            finish()
            let lang = localStorage.getItem('locale')
            let cookieFlag = localStorage.getItem('cookieFlag')
            // localStorage.clear()
            localStorage.setItem('locale', lang)
            localStorage.setItem('authorized', 'false')
            localStorage.setItem('cookieFlag', cookieFlag)
            Store.commit('SetAuthorized', false)
            Store.stompClient.disconnect()
            // Router.push('/login')
            window.location.replace('/lk/login')
          })
      } else {
        scheduleNextCheck()
      }
      if (once) clearInterval(timeoutCheckerTask)
    }

    /**
     * Calculate time of next check and schedule it.
     */
    function scheduleNextCheck () {
      if (sessionState.loggedIn) {
        // initialize timeout checker if it is called for the first time
        if (timeoutCheckerTask === null) {
          sessionState.updateLastAccessTime()
          checkInterval = sessionState.timeout - sessionState.timeoutThreshold
        }
        const nextCheckTime = sessionState.lastAccessTime + checkInterval
        const interval = nextCheckTime - Date.now()
        const currentDate = new Date()
        timeoutCheckerTask = setInterval(() => checkSessionTimeout(currentDate, interval < sessionState.timeoutThreshold), interval)
      }
      return sessionState.loggedIn
    }

    Vue.sessionStart = function (time) {
      sessionState.loggedIn = true
      sessionState.setTimeoutSeconds(time)
      scheduleNextCheck()
    }

    Vue.finishSession = function () {
      finish()
    }

    // TODO переписать под vue3
    Vue.sessionRefresh = function () {
      if (sessionState.loggedIn) {
        Store.dispatch('refreshSession')
          .then(() => {
            cancel()
            scheduleNextCheck()
          })
          .catch(() => {
            notify({
              type: 'error',
              title: 'Cannot contact server',
              text: 'Communication error, please try again later.'
            })
          })
      }
    }
  }
}

export default Session
