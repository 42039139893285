import Noty from 'noty'

export default function notyAlert (msg, okMsg) {
  const noty = new Noty({
    type: 'alert',
    text: msg,
    timeout: false,
    layout: 'center',
    theme: 'mint',
    buttons: [
      Noty.button(okMsg, 'btn btn-success', () => noty.close(), { id: 'button1', 'data-status': 'ok' })
    ]
  })
  noty.show()
  document.querySelector('.noty_body').style.backgroundColor = '#79da7a'
  document.querySelector('.noty_buttons').style.backgroundColor = '#79da7a'
  return noty
}
