/* eslint-disable */
import { UISignedRequest } from '@/models/UISignedRequest'
import { Parameter } from '@/models/Parameter'

String.prototype.toByteArray = function () {
  // let charList = this.split('')
  // let uintArray = []
  // for (let i = 0; i < charList.length; i++) {
  //   uintArray.push(charList[i].charCodeAt(0))
  // }
  // return new Uint8Array(uintArray)
  let utf8 = []
  let str = this.split('')
  for (let i=0; i < str.length; i++) {
    let charcode = str[i].charCodeAt(0)
    if (charcode < 0x80) utf8.push(charcode)
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6),
        0x80 | (charcode & 0x3f))
    }
    else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(0xe0 | (charcode >> 12),
        0x80 | ((charcode>>6) & 0x3f),
        0x80 | (charcode & 0x3f))
    }
    // surrogate pair
    else {
      i++
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode = 0x10000 + (((charcode & 0x3ff)<<10)
        | (str.charCodeAt(i) & 0x3ff))
      utf8.push(0xf0 | (charcode >>18),
        0x80 | ((charcode>>12) & 0x3f),
        0x80 | ((charcode>>6) & 0x3f),
        0x80 | (charcode & 0x3f))
    }
  }
  return new Uint8Array(utf8)
};

require('@/modules/crypt.min.js')

export class SignService {
  constructor(keyPair, userID) {
    this.cryptService = new CryptService()
    this.keyPair = keyPair == ''? $rootScope.userId : keyPair
    this.keyPair = this.cryptService.importKeys(this.keyPair)
    this.userID = userID
  }
  /**
   * Create UI signed request
   * @param actionId {int}
   * @param obj {Object}
   * @returns {UISignedRequest}
   */
  createSignedRequest(actionId, obj) {
    let request = new UISignedRequest()
    let param = new Parameter(this.userID, actionId, obj)
    let json = JSON.stringify(param)
    request.user_signature = this.cryptService.sign(this.keyPair, sha256.array(json.toByteArray()))
    request.user_params = param
    return request
  }
}
