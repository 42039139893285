<template>
  <div
    v-if="flagShowModal"
    class="ModalWindow"
    :style="{ width: width + 'px' }"
  >
    <span
      class="ModalWindow-CloseButton"
      @click="closeModal"
    />
    <iframe
      width="100%"
      height="100%"
      :src="video"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script>
let widthScreen = window.innerWidth
export default {
  computed: {
    width: function () {
      return widthScreen / 2
    },
    flagShowModal: function () {
      return this.$store.getters.GetModalFlag
    },
    video: function () {
      return this.$store.getters.GetVideoForModal
    }
  },
  methods: {
    closeModal () {
      this.$store.commit('SetModalFlag', false)
    }
  }
}
</script>

<style lang="stylus">
.ModalWindow
  position fixed
  left: 50%
  top: 50%
  transform: translate(-50%,-50%)
  height 440px
  background-color white
  border 3px solid black
  z-index 400
  &-CloseButton
    display block
    position absolute
    right 10px
    top 10px
    width 30px
    height 30px
    background-color: black
    cursor pointer
    &:before, &:after
      content ''
      display block
      height 3px
      width 100%
      position absolute
      top 13px
      border-radius 50%
      background-color: #fff
    &:before
      transform rotate(45deg)
    &:after
      transform rotate(-45deg)
</style>
