import { SignService } from '@/Services/SignService'
import RequisitesRequestModel from '@/models/P2P/RequisitesRequestModel'
import { REQUISITES_TYPES_FOR_REQUEST } from '@/models/P2P/RequisitesModel'
import { getDeepSortedPropsObj } from '@/utils/common'

export default class  {
  constructor (data) {
    this.data = data
  }

  getDataWithHash () {
    const sortedData = getDeepSortedPropsObj(this.data)
    // // Для подписи используются отсортированные свойства в объекте
    // Object.keys(this.data).sort((a, b) => a.localeCompare(b)).forEach(prop => {
    //   sortedData[REQUISITES_TYPES_FOR_REQUEST[prop]] = this.data[prop]
    // })
    const hashArr = sortedData.map(i => sha256(JSON.stringify(i)))
    return new RequisitesRequestModel(hashArr, sortedData)
  }
}
